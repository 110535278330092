<template>
  <v-btn
    text
    @click="$router.push(to ? to : '/empresas')"
    class="mb8"
  >
    <v-icon
      small
      style="margin-right:8px"
      v-text="'arrow_back'"
    />
    {{text ? text: 'Volver al inicio'}}
  </v-btn>
</template>
<script>
export default {
  name: "ButtonBackHome",
  props: {
    text: String,
    to: String,
  },
};
</script>