export default {
  data() {
    return {
      cargando: false,
      sexos: [
        { text: "Femenino", value: "femenino" },
        { text: "Masculino", value: "masculino" },
        { text: "Prefiero no especificar", value: "prefiero no especificar" },
      ],
    };
  },
  methods: {
    async _getThings(url = null, whatToGet = null, params = "") {
      if (url && whatToGet) {
        this.cargando = true;
        try {
          const { status, body } = await this.$http.get(
            url + params,
            this.$headers
          );
          if (status === 200 && whatToGet in body) {
            this[whatToGet] = body[whatToGet];
          } else {
            this.$emit("httpError", res);
          }
          this.cargando = false;
        } catch (err) {
          this._handleError(err);
        }
      }
    },
    async _saveAll(
      ruta = null,
      data = null,
      whatShouldLookFor = null,
      functionHandleSuccess = null
    ) {
      if (ruta && data && whatShouldLookFor) {
        this.cargando = true;
        try {
          const { status, body } = await this.$http.post(
            ruta,
            data,
            this.$headers
          );
          this._handleResponse(
            status,
            whatShouldLookFor,
            body,
            functionHandleSuccess
          );
          this.cargando = false;
        } catch (err) {
          this._handleError(err);
        }
      }
    },
    async _updateAll(
      ruta = null,
      data = null,
      whatShouldLookFor = null,
      functionHandleSuccess = null
    ) {
      if (ruta && data && whatShouldLookFor) {
        this.cargando = true;
        try {
          const { status, body } = await this.$http.put(
            ruta,
            data,
            this.$headers
          );
          this._handleResponse(
            status,
            whatShouldLookFor,
            body,
            functionHandleSuccess
          );
          this.cargando = false;
        } catch (err) {
          this._handleError(err);
        }
      }
    },
    async _deleteAll(
      ruta = null,
      whatShouldLookFor = null,
      functionHandleSuccess = null
    ) {
      if (ruta && whatShouldLookFor) {
        this.cargando = true;
        try {
          const { status, body } = await this.$http.delete(ruta, this.$headers);
          this._handleResponse(
            status,
            whatShouldLookFor,
            body,
            functionHandleSuccess
          );
          this.cargando = false;
        } catch (err) {
          this._handleError(err);
        }
      }
    },
    _handleResponse(
      responseStatus,
      whatShouldLookFor,
      responseBody,
      functionHandleSuccess
    ) {
      if (
        (responseStatus === 200 || responseStatus === 201) &&
        whatShouldLookFor in responseBody
      ) {
        if (functionHandleSuccess) this[functionHandleSuccess](responseBody);
      } else {
        this.$emit("msg", responseBody.error);
      }
    },
    _handleError(err) {
      try {
        switch (err.status) {
          case 405:
            this.$emit("msg", "Acceso negado");
            break;
          case 401:
            this.$emit("msg", "No detectamos tu sesión");
            this.$router.push("/");
            break;
        }
      } catch (e) {}
      this.$emit("httpError", err);
      this.cargando = false;
    },
    _validateAll(data = null, errors = "errors") {
      /**
       * Por estandar todo lo que verificara estara en "errors",
       * sin embargo como existen validaciones varias se permite
       * que "errors" pueda cambiar, por lo que se pone "errors
       * por default
       */
      if (data) {
        Object.keys(this[errors]).forEach((e) => {
          this[errors][e] = this._isEmpty(this[data][e]);
        });
        return this._hasErrors(this[errors]);
      } else {
        return false;
      }
    },
    _isEmpty(data) {
      return data ? null : this.$llenarCampo;
    },
    _hasErrors(arr) {
      //this.$llenarCampo esta en configuracion global en main.js
      return Object.values(arr).indexOf(this.$llenarCampo) <= -1 ? true : false;
    },
    _clearFields(prop = null) {
      this._limpiarCampos(prop);
    },
    _limpiarCampos(propMustBeCleaned = null) {
      if (propMustBeCleaned)
        Object.keys(this[propMustBeCleaned]).forEach((e) => {
          this[propMustBeCleaned][e] = null;
        });
      Object.keys(this.errors).forEach((e) => {
        this.errors[e] = null;
      });
    },
  },
};
