<template>
  <section class="BeneficiariosForm empresas__inner container mt24 mb24">
    <ButtonBackHome />
    <header class="empresas__header">
      <h2 class="empresas_home__heading heading">Registra tus beneficiarios</h2>
      <div class="empresas_home__text">
        Para poder registrar a un beneficiario es importante que llenes
        correctamente todos los campos marcados con *.<br /><span style="background: #ecb731">&nbsp;&nbsp;Se mostrará una ventana si detectamos que el beneficiario ya se encuentra registrado en nuestra base de datos, te pedimos evites la generación de pacientes duplicados para poder ofrecer una mejor experiencia a los beneficiarios&nbsp;</span> </div>
    </header>
    <v-card
      flat
      class=""
      :disabled="cargando"
      :loading="cargando"
    >
      <v-subheader v-text="'Informacion general'" />
      <v-select
        label="Programa al que pertenecerá"
        v-model="programa"
        filled
        :items="programas"
        item-text="desc_prg"
        item-value="programa"
        :disabled="!showSelectPrograma"
      />

      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="4"
        >
          <v-text-field
            v-model="paciente.nombre_pac"
            :error-messages="errors.nombre_pac"
            label="Nombre*"
            filled
            @blur="_checkIfExists"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
        >
          <v-text-field
            v-model="paciente.ape_pat_pac"
            :error-messages="errors.ape_pat_pac"
            label="Apellido paterno*"
            filled
            @blur="_checkIfExists"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
        >
          <v-text-field
            v-model="paciente.ape_mat_pac"
            :error-messages="errors.ape_mat_pac"
            label="Apellido materno*"
            filled
            @blur="_checkIfExists"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
        >
          <v-select
            label="Sexo*"
            filled
            :items="[
              { text: 'Femenino', value: 'femenino' },
              { text: 'Masculino', value: 'masculino' },
              {
                text: 'Prefiero no especificar',
                value: 'prefiero no especificar',
              },
            ]"
            v-model="paciente.sexo_pac"
            :error-messages="errors.sexo_pac"
            @change="_checkIfExists"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
        >
          <v-text-field
            v-model="paciente.fecha_nac_pac"
            label="Fecha de nacimiento*"
            placeholder="YYYY-MM-DD"
            v-mask="'####-##-##'"
            hint="Año-mes-día"
            prepend-inner-icon="today"
            filled
            :error-messages="errors.fecha_nac_pac"
            @blur="_checkIfExists"
          />
        </v-col>
      </v-row>
      <v-subheader v-text="'Informacion demográfica'" />
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <AutocompleteDataEstados
            label="Estado*"
            isSelect
            v-model="paciente.id_edo_pac"
            :error="errors.id_edo_pac"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <AutocompleteDataMunicipios
            label="Municipio*"
            isSelect
            :estado="paciente.id_edo_pac"
            v-model="paciente.id_mpio_pac"
            :error="errors.id_mpio_pac"
          />
        </v-col>

        <v-col
          cols="12"
          sm="12"
          md="4"
        >
          <v-text-field
            v-model="paciente.calle_pac"
            :error-messages="errors.calle_pac"
            label="Calle*"
            filled
          />
        </v-col>
        <v-col
          cols="6"
          sm="6"
          md="2"
        >
          <v-text-field
            v-model="paciente.num_ext_pac"
            :error-messages="errors.num_ext_pac"
            label="# ext*"
            filled
          />
        </v-col>
        <v-col
          cols="6"
          sm="6"
          md="2"
        >
          <v-text-field
            v-model="paciente.num_int_pac"
            :error-messages="errors.num_int_pac"
            label="# int"
            filled
          />
        </v-col>
        <v-col
          cols="6"
          sm="12"
          md="4"
        >
          <v-text-field
            v-model="paciente.colonia_pac"
            :error-messages="errors.colonia_pac"
            label="Colonia*"
            filled
          />
        </v-col>
      </v-row>
      <v-card-actions class="card_no_padding">
        <v-spacer />
        <v-btn
          text
          @click="$router.push('/empresas')"
          v-text="'Cancelar'"
        />
        <v-btn
          class="primary"
          depressed
          v-text="'Guardar beneficiario'"
          @click="_savePac"
        />
      </v-card-actions>
    </v-card>
    <v-dialog
      id="existenPacientesDuplicados"
      v-model="showDialogExiste"
      max-width="500"
      persistent
    >
      <v-card
        :loading="cargando"
        :disabled="cargando"
      >
        <v-card-title v-html="'Hemos encontrado estos pacientes con los mismos datos'" />
        <v-card-subtitle v-html="
            '¿Alguno de los resultados es quien intentas registrar como beneficiario?'
          " />
        <v-list-item-group>
          <div
            v-for="(item, i) in pacientes"
            :key="i"
          >
            <v-divider />
            <v-list-item @click="_saveSelectedPac(item)">
              <v-list-item-content>
                <v-list-item-title v-text="
                    `${item.nombre_pac} ${item.ape_pat_pac} ${item.ape_mat_pac}`
                  " />
                <v-list-item-subtitle v-text="
                    `${item.fecha_nac_pac}, Domicilio: ${item.calle_pac.slice(
                      0,
                      8
                    )}...`
                  " />
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon v-text="'keyboard_arrow_right'" />
              </v-list-item-icon>
            </v-list-item>
          </div>
        </v-list-item-group>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            v-text="'Cancelar'"
            @click="showDialogExiste = false"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      width="500"
      v-model="showDialogContinuar"
    >
      <v-card>
        <v-card-title>¿Deseas continuar registrando beneficiarios?</v-card-title>
        <v-card-text>
          <v-btn
            block
            class="primary mt24"
            depressed
            v-text="'Continuar registrando beneficiarios'"
            @click="showDialogContinuar = false"
          />
          <v-btn
            block
            class="mt24"
            outlined
            depressed
            v-text="'Ver los beneficiarios registrados'"
            @click="$router.push('/empresas/beneficiarios')"
          />
          <v-btn
            block
            class="mt24"
            depressed
            text
            v-text="'Ir al inicio'"
            @click="$router.push('/empresas')"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </section>
</template>
<script>
import standard from "@/mixins/standard.js";
import forms from "@/mixins/forms.js";
import beneficiariosForm from "@/mixins/beneficiariosForm.js";
export default {
  name: "BeneficiariosForm",
  mixins: [standard, forms, beneficiariosForm],

  data() {
    return {};
  },
  methods: {
    _getProgramas() {
      this._getThings("beneficiarios/programas", "programas");
    },
    _savePac() {
      if (this._validateAll("paciente") && this._validateFechaNac()) {
        this.cargando = true;
        const data = {
          paciente: this.paciente,
          programa: this.programa,
          existe: "no",
        };
        this._saveAll("beneficiarios", data, "id", "_handleBeneficiarioRes");
      } else {
        this.$emit("msg", this.$lleneCampos);
      }
    },
    _saveSelectedPac(pac) {
      this.cargando = true;
      const data = {
        paciente: pac.id,
        programa: this.programa,
        existe: "si",
      };
      this._saveAll("beneficiarios", data, "id", "_handleBeneficiarioRes");
    },
    _handleBeneficiarioRes(res) {
      window.scrollTo(0, 0);
      this.pacientes = [];
      this.showDialogExiste = false;
      this._limpiarCampos("paciente");
      this.$emit("msg", res.msg);
      this.showDialogContinuar = true;
      //aqui preguntamos si quiere seguir guardando o no
    },
  },
  created() {
    this._getProgramas();
  },
  watch: {
    programas() {
      if (this.programas.length === 1) {
        this.programa = this.programas[0].programa;
        this.showSelectPrograma = false;
      } else {
        this.programa = null;
        this.showSelectPrograma = true;
      }
    },
  },
};
</script>