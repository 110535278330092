import AutocompleteDataEstados from "@/components/forms/autocompletes/AutocompleteDataEstados.vue";
import AutocompleteDataMunicipios from "@/components/forms/autocompletes/AutocompleteDataMunicipios.vue";
import DatePicker from "@/components/forms/pickers/DatePicker.vue";
export default {
    components: {
        AutocompleteDataEstados,
        AutocompleteDataMunicipios,
        DatePicker,
    },
    data() {
        return {
            showDialogAcciones: false,
            showDialogExiste: false,
            showSelectPrograma: false,
            showDialogContinuar: false,
            programas: [],
            programa: null,
            pacientes: [],
            paciente: {
                id: null,
                id_pac: null,
                nombre_pac: null,
                ape_pat_pac: null,
                ape_mat_pac: null,
                sexo_pac: null,
                fecha_nac_pac: null,
                id_edo_pac: null,
                id_edo_pac: null,
                id_mpio_pac: null,
                calle_pac: null,
                num_ext_pac: null,
                num_int_pac: null,
                colonia_pac: null,
            },
            errors: {
                nombre_pac: null,
                ape_pat_pac: null,
                ape_mat_pac: null,
                sexo_pac: null,
                fecha_nac_pac: null,
                id_edo_pac: null,
                id_edo_pac: null,
                id_mpio_pac: null,
                calle_pac: null,
                num_ext_pac: null,
                colonia_pac: null,
            },
        }
    },
    methods: {
        _checkIfExists() {
            if (
                !this.paciente.id &&
                this.paciente.nombre_pac &&
                (this.paciente.ape_pat_pac || this.paciente.ape_mat_pac)
            ) {
                this._searchPac(this.paciente);
            }
        },
        async _searchPac(data) {
            try {
                const { status, body } = await this.$http.post(
                    "pacientes/buscar",
                    { paciente: data },
                    this.$headers
                );
                this.pacientes =
                    status === 200 && "pacientes" in body ? body.pacientes : [];
            } catch (err) {
                this.pacientes = [];
            }
        },
        _pacsHasData() {
            if (this.pacientes.length >= 1) this.showDialogExiste = true;
        },
        _validateFechaNac() {
            this.errors.fecha_nac_pac = this.$Datetime.fromSQL(
                this.paciente.fecha_nac_pac
            ).isValid
                ? null
                : "Ingrese una fecha válida";
            return this.errors.fecha_nac_pac ? false : true;
        },
    },
    watch: {
        pacientes: {
            deep: true,
            immediate: true,
            handler: "_pacsHasData",
        },
        showDialogExiste(a) {
            if (!a) {
                this.pacientes = [];
            }
        },
    }
}