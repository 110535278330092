<template>
  <div class="AutocompleteDataEstados">
    <v-autocomplete
      v-if="!isSelect"
      v-model="value"
      append-icon="search"
      :error-messages="error"
      item-text="estado"
      item-value="id"
      :items="estados"
      :label="label ? label : 'Buscar estado'"
      filled
      @change="_searchAndSendEstado"
      :autocomplete="false"
      :name="Math.random()"
    >
      <template v-slot:item="data">
        <v-list-item-content>
          <v-list-item-title v-text="data.item.estado" />
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <v-select
      v-else
      v-model="value"
      :error-messages="error"
      item-text="estado"
      item-value="id"
      :items="estados"
      :label="label ? label : 'Seleccionar estado'"
      filled
      :autocomplete="false"
      :name="Math.random()"
      @change="_searchAndSendEstado"
    />
  </div>
</template>
<script>
import getData from "@/mixins/getData.js";
export default {
  name: "AutocompleteDataEstados",
  mixins: [getData],
  props: {
    value: String,
    isSelect: Boolean,
    label: String,
    error: String,
  },
  data() {
    return {};
  },
  methods: {
    _searchAndSendEstado(a) {
      this.$emit("input", a);
      let i = this.estados.findIndex(function (b) {
        return b.id === a;
      });
      if (i >= 0) {
        this.$emit("change", this.estados[i]);
      }
    },
  },
  created() {
    this._getEdos();
  },
};
</script>