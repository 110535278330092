import ButtonAdd from "@/components/forms/buttons/ButtonAdd.vue";
import ButtonCardActions from "@/components/forms/buttons/ButtonCardActions.vue";
import ButtonBackHome from '@/components/forms/buttons/ButtonBackHome.vue';
export default {
    data() {
        return {
            cargando: false,
            search: "",
        }
    },
    components: { ButtonAdd, ButtonCardActions, ButtonBackHome },
    methods: {
        _handleError(err) {
            this.$emit("httpError", err);
            this.cargando = false;
        },
        async _getAll(whatToGet = null, params = '') {
            if (whatToGet) {
                this.cargando = true;
                try {
                    const { status, body } = await this.$http.get(
                        whatToGet + params,
                        this.$headers
                    );
                    if (status === 200 && whatToGet in body) {
                        this[whatToGet] = body[whatToGet];
                    } else {
                        this.$emit("httpError", res);
                    }
                    this.cargando = false;
                } catch (err) {
                    this._handleError(err);
                }
            }
        },
    },
    beforeCreate(prevent = false) {

        if (
            !this.$session.exists() &&
            !this.$session.get("tipo") &&
            //y si es diferente de donador & personal
            (this.$session.get('tipo') != 'donador' && this.$session.get('tipo') != 'personal')) {
            this.$emit('msg', 'Es necesario inicies sesión')
            this.$session.destroy()
            const ruta = this.$route.fullPath.includes('persona') ? '/personal' : '/empresas/login'
            this.$router.push(ruta);
        }
    }
}