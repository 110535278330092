<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :disabled="disabled"
          :error-messages="error"
          filled
          :label="label ? label:'Seleccione una fecha'"
          prepend-inner-icon="event"
          readonly
          v-model="valor"
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="valor"
        :active-picker.sync="activePicker"
        :max="!sinceToday ? dateLimit : dateLimitYear"
        :min="!sinceToday ? '1900-01-01': dateLimit"
        @change="_doSave"
        @blur="$emit('blur',valor)"
      />
    </v-menu>
  </div>
</template>
<script>
export default {
  name: "DatePicker",
  props: {
    value: String,
    label: String,
    error: String,
    sinceToday: Boolean,
    disabled: Boolean,
    addDays: [String, Number],
  },
  data() {
    return {
      test: "",
      activePicker: null,
      valor: null,
      menu: false,
      dateLimit: null,
      dateLimitYear: new Date(
        new Date().setFullYear(new Date().getFullYear() + 1)
      )
        .toISOString()
        .substr(0, 10),
    };
  },
  watch: {
    menu(val) {
      if (!this.value) val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    value(a) {
      this.valor = a;
    },
    addDays: {
      deep: true,
      immediate: true,
      handler: "_doDateLimit",
    },
  },
  created() {
    this.valor = this.value;
    this._doDateLimit();
  },
  methods: {
    _doSave(date) {
      this.$refs.menu.save(date);
      this.$emit("input", date);
      this.$emit("blur", date);
      this.$emit("change", date);
    },
    _doDateLimit() {
      let date = this.$Datetime.now().setZone("America/Mexico_City");
      if (this.addDays) date = date.plus({ days: this.addDays });
      this.dateLimit = date.toISO();
    },
  },
};
</script>