<template>
  <v-card-actions class="ButtonCardActions card_no_padding">
    <v-spacer />
    <v-btn
      v-if="to"
      text
      v-text="cancelText ? cancelText : 'Cancelar'"
      :to="to"
      @click="$emit('cancel')"
    />

    <v-btn
      v-text="text"
      class="primary"
      depressed
      @click="$emit('click',Date.now())"
    />
  </v-card-actions>
</template>
<script>
export default {
  name: "ButtonCardActions",
  props: {
    to: String,
    text: String,
    cancelText: String,
  },
};
</script>