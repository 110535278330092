export default {
    data() {
        return {
            cargando: false,
            estados: [],
            municipios: []
        }
    },
    methods: {
        _handleError(err) {
            this.$emit("httpError", err);
            this.cargando = false;
        },
        async _getAll(whatToGet = null, params = '') {
            if (whatToGet) {
                this.cargando = true;
                try {
                    const { status, body } = await this.$http.get(
                        whatToGet + params,
                        this.$headers
                    );
                    if (status === 200 && whatToGet in body) {
                        this[whatToGet] = body[whatToGet];
                    } else {
                        this.$emit("httpError", res);
                    }
                    this.cargando = false;
                } catch (err) {
                    this._handleError(err);
                }
            }
        },
        _getEdos() {
            this.cargando = true;
            this.$http.get("data/estados").then(
                res => {
                    this.cargando = false;
                    if (res.status === 200 && "estados" in res.body) {
                        this.estados = res.body.estados;
                    } else {
                        this.$emit("httpError", res);
                    }
                },
                err => {
                    this.cargando = false;
                    this.$emit("httpError", err);
                }
            );
        },
        _getMpios(a = '') {
            this.cargando = true;
            this.$http.get("data/municipios" + a, this.$headers).then(
                res => {
                    this.cargando = false;
                    if (res.status === 200 && "municipios" in res.body) {
                        this.municipios = res.body.municipios;
                    } else {
                        this.$emit("httpError", res);
                    }
                },
                err => {
                    this.cargando = false;
                    this.$emit("httpError", err);
                }
            );
        }
    }
}